import React, { useContext, createContext, useState, useEffect } from 'react'
import {
  OrderFoodOnlineType,
  OrderNumberOfPeopleType,
  OrderCustomerGenderType,
  OrderProgramType,
  OrderProgramDurationType,
  OrderWeekMealLengthType,
  OrderNumberOfMealsType,
  OrderCountOfMeals,
  OrderNumberOfMeals,
  OrderCustomerMenuType,
  OrderCustomerProgramType,
} from './order-food-online-type'
import { getOrderNumberOfKcal, isCompanyOrder } from './getters'
import { OrderFoodDeliveryType } from './order-food-delivery-type'
import { useLocalStorage } from '../../../../shared/lib/local-storage'

export const OrderStorageType = {
  orderFoodOnline: 'order-food-online',
  orderFoodContact: 'order-food-contact-information',
  orderFoodDelivery: 'order-food-delivery',
}

const initialState = () => {
  const state = {
    [OrderFoodOnlineType.NumberOfPeople]: OrderNumberOfPeopleType.One,
    [OrderFoodOnlineType.Exeptions]: [],
    [OrderFoodOnlineType.CustomerGender]: OrderCustomerGenderType.Female,
    [OrderFoodOnlineType.CustomerMenu]: OrderCustomerMenuType.Meat,
    [OrderFoodOnlineType.OrderProgram]: OrderCustomerProgramType.Slimming,
    [OrderFoodOnlineType.Program]: OrderProgramType.WeightLossProgram,
    [OrderFoodOnlineType.NumberOfMeals]: OrderNumberOfMeals.Five,
    [OrderFoodOnlineType.CountOfMeals]: OrderCountOfMeals.Five,
    [OrderFoodOnlineType.ProgramDuration]: OrderProgramDurationType.OneMonth,
    [OrderFoodOnlineType.WeekMealLength]: OrderWeekMealLengthType.SixDays,
    [OrderFoodOnlineType.Discount]: 0,
    [OrderFoodOnlineType.PriceDiscount]: 0,
    [OrderFoodOnlineType.PromoCode]: null,
    [OrderFoodOnlineType.IsCompanyOrder]: false,
    [OrderFoodDeliveryType.Time]: [],
    [OrderFoodDeliveryType.Price]: null,
    [OrderFoodDeliveryType.PaymentSystem]: undefined,
    [OrderFoodDeliveryType.Terms1]: false,
    [OrderFoodDeliveryType.Terms2]: false,
    [OrderFoodOnlineType.Duration]: 'Měsíc'
  }

  return {
    ...state,
    ...{
      [OrderFoodOnlineType.NumberOfKcal]: getOrderNumberOfKcal(state),
    },
  }
}

export const OrderFoodContext = createContext(initialState())

export const useOrderFoodContext = () => {
  return useContext(OrderFoodContext)
}

export const OrderFoodContextProvider = props => {
  const [state, setState] = useState(initialState())

  const handleState = data => {
    setState({
      ...state,
      ...data,
    })
  }

  return (
    <OrderFoodContext.Provider value={[state, handleState]}>
      {props.children}
    </OrderFoodContext.Provider>
  )
}

export const useClearOrderStorage = () => {
  const [, setOrderFoodOnline] = useLocalStorage(
    OrderStorageType.orderFoodOnline
  )
  const [, setOrderFoodContact] = useLocalStorage(
    OrderStorageType.orderFoodContact
  )
  const [, setOrderFoodDelivery] = useLocalStorage(
    OrderStorageType.orderFoodDelivery
  )

  const handleClearStorage = () => {
    setOrderFoodOnline({})
    setOrderFoodContact({})
    setOrderFoodDelivery({})
  }

  return {
    clearOrderStorage: handleClearStorage,
  }
}
