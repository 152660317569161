import cx from 'classnames'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { handleMenuLinkClick } from '../../helpers'
import { useLangContext } from '../../utils/lang'
import { Button } from '../button'
import { Container } from '../container'
import { Logo } from '../header/logo'
import { LocalizedLink } from '../localized-link'
import styles from './footer.module.css'
import styles2 from '../cookie-agreement-modal/styles.module.css'
import fb from './icons/facebook.svg'
import logo from './icons/footer-logo.svg'
import user from './icons/icon-user.svg'
import instagram from './icons/instagram.svg'
import twitter from './icons/twitter.svg'
import mastercard from './icons/master-card.svg'
import maestro from './icons/maestro.svg'
import visa from './icons/visa.svg'
import gopay from './icons/go-pay.svg'
import { Prefooter } from '../prefooter-v2'
import { Button2 } from '../button2'
import { useModal } from '../modal2'

export const Footer2 = ({location}) => {
  const { t } = useTranslation()
  const { lang } = useLangContext()
  const isHomepage =
    window.location.pathname === '/' || window.location.pathname === '/en/'

  const isOnlineForm = window.location.href.indexOf('order-food-online') > -1
  
  const { show, hide, RenderModal } = useModal()
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const isCalculatorPage =
      location.pathname.includes('/calculator') ||
      location.pathname.includes('/en/calculator') 

  useEffect(() => {
    
    if (isCalculatorPage) {
      const targetDiv = document.getElementById('footer')
      if (targetDiv) {
        targetDiv.style.display = 'none'
      }
    }
  }, [])

  return (
    <footer
      id="footer"
      className={styles.footer}
      style={
        isOnlineForm
          ? {
              padding: '50px 0',
              marginTop: `${isHomepage || isOnlineForm ? 0 : ''}`,
            }
          : {}
      }
    >
      <RenderModal className={styles2.cookieModal}>
        <h3 className={styles2.title}>{t('cookieModal.title')}</h3>
        <p className={styles2.p}>
          {t('cookieModal.text')}{' '}
          <LocalizedLink
            to="/pravidla_pouzivani_cookies.pdf"
            target="_blank"
            rel="noopener norefferer"
          >
            {t('cookieModal.link')}
          </LocalizedLink>
        </p>
        <div className={styles2.btnsBlock}>
          <Button2 color="secondary" className={styles2.btn} handleClick={hide}>
            {t('cookieModal.btn1')}
          </Button2>
          <Button2 color="secondary" className={styles2.btn} handleClick={hide}>
            {t('cookieModal.btn2')}
          </Button2>
        </div>
      </RenderModal>
      {!isOnlineForm && <Prefooter />}
      <Container isWide={true}>
        <div className={styles.footerCols}>
          <div className={styles.footerCol}>
            <LocalizedLink to="/">
              <img src={logo} alt="logo" />
            </LocalizedLink>
            <div className={cx(styles.socials)}>
              <a
                href="https://www.facebook.com/nutritionprocz"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={fb} alt="icon" />
              </a>
              <a
                href="https://www.instagram.com/nutritionprocz/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={instagram} alt="icon" />
              </a>
            </div>
            <div className={cx(styles.hideXS, styles.conditions)}>
              <p>{t('general.footer.terms2')}</p>
              <p>
                <LocalizedLink to="/gdpr">
                  {t('general.footer.link')}
                </LocalizedLink>
                {' | '}
                <LocalizedLink
                  to="/terms"
                  target="_blank"
                  rel="noopener norefferer"
                >
                  {t('general.footer.terms1')}
                </LocalizedLink>
              </p>
              <p>
                <LocalizedLink
                  to="/pravidla_pouzivani_cookies.pdf"
                  target="_blank"
                  rel="noopener norefferer"
                >
                  {t('general.footer.cookieLink1')}
                </LocalizedLink>
                {' | '}
                <button onClick={show} className={styles.cookieBtn}>
                  {t('general.footer.cookieLink2')}
                </button>
              </p>
              <div className={cx(styles.footerPaymentcards, styles.hideXS)}>
                <img src={mastercard} alt="mastercard" />
                <img src={visa} alt="visa" />
                <img src={maestro} alt="maestro" />
                <img src={gopay} alt="gopay" />
              </div>
              <p>
                <span>Copyright &#169; {currentYear} NutritionPro s.r.o.</span>
              </p>
            </div>
          </div>
          <div className={styles.footerCol}>
            <div className={cx(styles.footerMenu, styles.hideXS)}>
              <h4 className={styles.footerColTitle}>
                {t('general.footer.menuLinks')}
              </h4>
              <Button
                type="unstyled"
                handleClick={() =>
                  handleMenuLinkClick({ link: '/#programs' }, lang)
                }
              >
                {t('menu.Programy')}
              </Button>
              <Button
                type="unstyled"
                handleClick={() =>
                  handleMenuLinkClick({ link: '/#food' }, lang)
                }
              >
                {t('menu.Jídelníček')}
              </Button>
              <Button
                type="unstyled"
                handleClick={() =>
                  handleMenuLinkClick({ link: '/#calculator' }, lang)
                }
              >
                {t('menu.Cena')}
              </Button>
              <LocalizedLink
                name="Kariéra"
                to="http://jobs.nutritionpro.cz/"
                target="_blank"
              >
                {t('menu.Kariéra')}
              </LocalizedLink>
            </div>
          </div>

          <div className={cx(styles.footerCol, styles.contacts)}>
            <h4 className={styles.footerColTitle}>
              {t('general.footer.contactCustomers')}
            </h4>
            <p className={styles.contactPhone}>+420 226 288 200</p>
            <p className={styles.contactEmail}>info@nutritionpro.cz</p>
            <h4 className={styles.footerColTitle}>
              {t('general.footer.contactPartners')}
            </h4>
            <p>partnership@nutritionpro.cz</p>
          </div>
          <div className={cx(styles.footerCol, styles.hours)}>
            <h4 className={styles.footerColTitle}>
              {t('general.footer.hours')}
            </h4>
            <p>
              <span style={{ marginRight: '12px' }}>{t('menu.days')}</span>{' '}
              10:00–12:00 13:00–18:00
            </p>
            <p>
              <span style={{ marginRight: '12px' }}>{t('menu.days2')}</span>{' '}
              {t('menu.days2Text')}
            </p>
          </div>
        </div>
        <div
          className={cx(styles.mobileCol, styles.visibleXS, styles.conditions)}
        >
          <p>{t('general.footer.terms2')}</p>
          <p>
            <LocalizedLink to="/gdpr">{t('general.footer.link')}</LocalizedLink>
            {' | '}
            <LocalizedLink
              to="/terms"
              target="_blank"
              rel="noopener norefferer"
            >
              {t('general.footer.terms1')}
            </LocalizedLink>
          </p>
          <p>
            <LocalizedLink
              to="/pravidla_pouzivani_cookies.pdf"
              target="_blank"
              rel="noopener norefferer"
            >
              {t('general.footer.cookieLink1')}
            </LocalizedLink>
            {' | '}
            <button onClick={show} className={styles.cookieBtn}>
              {t('general.footer.cookieLink2')}
            </button>
          </p>
          <div className={cx(styles.footerPaymentcards)}>
            <img src={mastercard} alt="mastercard" />
            <img src={visa} alt="visa" />
            <img src={maestro} alt="maestro" />
            <img src={gopay} alt="gopay" />
          </div>
          <p>
            <span>Copyright &#169; {currentYear} NutritionPro s.r.o.</span>
          </p>
        </div>
      </Container>
    </footer>
  )
}
