import './layout.css'

import { StaticQuery } from 'gatsby'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useEffect, useReducer, useState } from 'react'
import Helmet from 'react-helmet'

import { AffiliateProvider } from '../../contexts/AffiliateContext'
import {
  HomepageTabsProvider,
  homepageTabsReducer,
  initialState,
} from '../../contexts/HomepageTabsContext'
import { withTrans } from '../../i18n/withTrans'
import { LangProvider } from '../../utils/lang'
import { Footer2 } from '../footer2'
import { Header2 } from '../header2'
import { Loader } from '../loader'
import { PreHeader } from '../preheader'

// import { I18nextProvider } from 'react-i18next';
// import i18next from './i18next';
const Layout = ({ children, t, i18n, location, pageContext }) => {
  const [state, dispatch] = useReducer(homepageTabsReducer, initialState)
  const [isLoading, setIsLoading] = useState(true)
  const [isHomepage, setIsHomepage] = useState(false)
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            menuLinks {
              name
              link
              section
            }
          }
        }
      }
    `
  )


  useEffect(() => {
    setIsHomepage(
      prev =>
        (window &&
          (window.location.pathname === '/' ||
            window.location.pathname === '/en/')) ||
        prev
    )
    setTimeout(() => {
      setIsLoading(prev => !prev)
    }, 1000)
  }, [site])

  /* AB-test to display blueghost-version or current-version of header */
  // const [headerVersion, setHeaderVersion] = useState("usp-test-A-header")
  // const versions = ["usp-test-A-header", "usp-test-B-header"]

  // useEffect(() => {
  //   const curVersion = localStorage.getItem("header-version")
  //   const randomIndex = getRandomInteger(1, 10) > 5 ? 1 : 0
  //   const randomVersion = versions[randomIndex]
  //   if (!curVersion || curVersion === undefined) {
  //     localStorage.setItem("header-version", randomVersion)
  //     setHeaderVersion(randomVersion)
  //   } else {
  //     setHeaderVersion(curVersion)
  //   }
  // }, [])

  // useEffect(() => {
  //   window.dataLayer = window.dataLayer || []
  //   window.dataLayer &&
  //     window.dataLayer.push({
  //       event: "ga.pageview",
  //       pageURL: "/",
  //       testovani: headerVersion,
  //     })
  // }, [headerVersion])

  // function getRandomInteger(min, max) {
  //   return Math.floor(Math.random() * (max - min + 1)) + min
  // }

  return (
    <AffiliateProvider>
      <HomepageTabsProvider
        value={{ activeTab: state.activeTab, dispatchAction: dispatch }}
      >
        <Helmet>
          {isHomepage && (
            <script
              src="//code.jivosite.com/widget.js"
              jv-id="TLJ5HRqinQ"
              async
            />
          )}
        </Helmet>
        {!isLoading ? (
          <div className="wrapper" id="root">
            {/* <PreHeader /> */}
            {/* {pageContext.layout !== "special" && ( */}
            <Header2 menuLinks={site.siteMetadata.menuLinks} isLight={true} location={location} />
            {/* )} */}
            <>
              <main>{children}</main>
              {/* {pageContext.layout !== "special" && ( */}
              <Footer2 location={location} />
              {/* )} */}
            </>
          </div>
        ) : (
          <Loader />
        )}
      </HomepageTabsProvider>
    </AffiliateProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

// export default withTrans(Layout)
