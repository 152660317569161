import cx from 'classnames'
import React, { useState } from 'react'
import styles from './info-block.module.css'
import icon from './icon.svg'
import arrowIcon from './arrow.svg'
import round from './round.png'
import seedling from './seedling.png'
import { Trans, useTranslation } from 'react-i18next'

import { useHomepageTabsContext } from '../../../contexts/HomepageTabsContext'
import { useSmoothScroll } from '../../../hooks/useSmoothScroll'
import { Button2 } from '../../button2'
import { ArrowIcon, CloseIcon } from './icons'

export const InfoBlock = ({ id }) => {
  const { t } = useTranslation()
  const [isVisible, setIsVisible] = useState(true)

  const hide = (e) => {
    e.stopPropagation();
    setIsVisible(false)
  }

  const scroll = useSmoothScroll()

  const openDeliverySection = (selector) => {
    scroll.animateScroll(document.getElementById(selector))
  }

  return (
    <>
      {isVisible && (
        <section className={styles.infoBlock} onClick={(e) => openDeliverySection('delivery-section')}>
          <div className={cx(styles.hideXS, styles.infoBlockWrap)}>
            <div className={cx(styles.infoBlockText)}>
              <div className={cx(styles.infoBlockTextWrap)}>
                <img src={round} alt="icon" />
                <p>
                  <Trans i18nKey="infoBlock.text">
                    Nově rozvažíme v <strong>9 městech</strong> České republiky.
                  </Trans>
                </p>
              </div>

              {/* <Button2
                className={styles.infoBlockLink}
                handleClick={e => {
                  openDeliverySection('delivery-section')
                }}
              >
                {t('infoBlock.btn1')}
                <ArrowIcon />
              </Button2> */}

              <div onClick={(e) => hide(e)} className={styles.closeIcon}>
                <CloseIcon />
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  )
}
