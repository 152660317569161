import React from "react"
import cx from "classnames"
import { DietologForm } from "../forms/dietolog-form"
import { useModal } from "../modal2"
import { Trans, useTranslation } from "react-i18next"

import styles from "./styles.module.css"
import { GiftCertificateForm } from "../forms/gift-certificate-form"

export const GiftVoucherModal = ({ btnClassName }) => {
  const { show, RenderModal } = useModal()
  const { t } = useTranslation()

  const openModal = () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer &&
      window.dataLayer.push({
        event: `click_menu_voucher`,
        pageURL: window.location.pathname,
      })
    show()

  }

  return (
    <>
      <button onClick={openModal} className={cx(styles.btn, btnClassName)}>
        {t("menu.btnText")}
      </button>
      <RenderModal className={cx("modalForm", styles.modal)}>
        <div className={styles.modalImg} />
        <h3 className="fancyUnderlineText fancyUnderlineText--orange sectionTitleNew text-center">
          <Trans i18nKey="menu.giftVoucher.title">
            Udělejte radost svým <span>snejbližším</span>
          </Trans>
        </h3>
        <h5 className={styles.subtitle}>
          {t("menu.giftVoucher.text")}
        </h5>
        <GiftCertificateForm />
      </RenderModal>
    </>
  )
}
