import cx from "classnames"
import { FastField, Field, Form, withFormik } from "formik"
import React, { useContext, useEffect, useRef } from "react"
import { Trans, useTranslation } from "react-i18next"
import * as Yup from "yup"
import { navigate } from "gatsby"
import { AffiliateContext } from "../../contexts/AffiliateContext"
import {
  formatPhoneNumber,
  getCookie,
  getReferrer,
  getUTM,
} from "../../helpers"
import { Button2 } from "../button2"
import styles from "./form2.module.css"
import { PhoneErrorMessage } from "./forms-error-messages"
import saleFormStyles from "./sale-form.module.css"
import { MaskedPhoneInput } from "./phone"

const rePhoneNumber = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

Yup.addMethod(Yup.string, "phone", function () {
  return this.test("phone", "Telefonní číslo musí obsahovat 12 znaků", value =>
    rePhoneNumber.test(value)
  )
})

export const GiftCertificateFormLayout = ({
  isSubmitting,
  values,
  errors,
  touched,
  setFieldValue,
  btnType = "secondary",
  btnText1,
  form_name = "konzultace-dietolog-exit-form",
}) => {
  const { t } = useTranslation()
  const affiliateContext = useContext(AffiliateContext)
  const { isAffiliate, affiliateId } = affiliateContext.affiliate
  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer &&
      window.dataLayer.push({
        event: `form_view_gift_voucher`,
        pageURL: window.location.pathname,
      })
  }, [])
  const phoneRef = useRef()

  const handleChange = () => {
    setFieldValue("isAffiliate", isAffiliate)
    setFieldValue("affiliateId", affiliateId)
  }

  useEffect(() => {
    handleChange()
  }, [affiliateContext, isAffiliate, affiliateId])

  return (
    <Form
      data-boom={true}
      name={form_name}
      method="post"
      className={cx(saleFormStyles.giftForm)}
    >
      <div className={cx(styles.inputField, saleFormStyles.inputField)}>
        <label
          className={cx(
            styles.label,
            saleFormStyles.inputFieldLabel,
            saleFormStyles.wideLabel
          )}
        >
          {t("forms.giftCertificateForm.textLabel")}
        </label>
        <div className={saleFormStyles.inputWrap}>
          <svg
            className={saleFormStyles.inputIcofn}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 17.5C14.4853 17.5 16.5 15.4853 16.5 13C16.5 10.5147 14.4853 8.50001 12 8.50001C9.51472 8.50001 7.5 10.5147 7.5 13C7.5 15.4853 9.51472 17.5 12 17.5Z"
              fill="#F3F3F1"
            />
            <path
              d="M14.9053 6.16667H9.09467C8.766 6.16667 8.5 5.90067 8.5 5.572V5.09467C8.5 4.766 8.766 4.5 9.09467 4.5H14.9053C15.234 4.5 15.5 4.766 15.5 5.09467V5.572C15.5 5.90067 15.234 6.16667 14.9053 6.16667Z"
              fill="#3DC383"
            />
            <path
              d="M7.5 8.83337H4.5V8.50004C4.5 7.76337 5.09667 7.1667 5.83333 7.1667H6.16667C6.90333 7.1667 7.5 7.76337 7.5 8.50004V8.83337Z"
              fill="#3DC383"
            />
            <path
              d="M16.5 8.83337H19.5V8.50004C19.5 7.76337 18.9033 7.1667 18.1667 7.1667H17.8333C17.0967 7.1667 16.5 7.76337 16.5 8.50004V8.83337Z"
              fill="#3DC383"
            />
            <path
              d="M5.66602 19.5V17C5.66602 15.7933 6.46602 14.78 7.55935 14.4466H16.4394C17.5327 14.78 18.3327 15.7933 18.3327 17V19.5H5.66602Z"
              fill="#F3F3F1"
            />
            <path
              d="M12.6667 17.4467C12.4467 17.48 12.2267 17.5 12 17.5C10.2133 17.5 8.67333 16.46 7.95333 14.9533C7.86667 14.7933 7.79333 14.62 7.74 14.4467C7.58 13.9933 7.5 13.5067 7.5 13C7.5 10.5133 9.51333 8.50001 12 8.50001C12.2267 8.50001 12.4467 8.52001 12.6667 8.55334C10.4933 8.87334 8.83333 10.74 8.83333 13C8.83333 13.5067 8.91333 13.9933 9.07333 14.4467C9.60667 16.02 10.98 17.2 12.6667 17.4467Z"
              fill="#D5DBE1"
            />
            <path
              d="M9.83333 5.09333V5.57333C9.83333 5.9 10.1 6.16667 10.4267 6.16667H9.09333C8.76667 6.16667 8.5 5.9 8.5 5.57333V5.09333C8.5 4.76667 8.76667 4.5 9.09333 4.5H10.4267C10.1 4.5 9.83333 4.76667 9.83333 5.09333Z"
              fill="#00B871"
            />
            <path
              d="M6.66666 7.2667C6.18 7.46004 5.83333 7.94004 5.83333 8.50004V8.83337H4.5V8.50004C4.5 7.7667 5.1 7.1667 5.83333 7.1667H6.16666C6.34 7.1667 6.51333 7.20004 6.66666 7.2667Z"
              fill="#00B871"
            />
            <path
              d="M18.6667 7.2667C18.18 7.46004 17.8333 7.94004 17.8333 8.50004V8.83337H16.5V8.50004C16.5 7.7667 17.1 7.1667 17.8333 7.1667H18.1667C18.34 7.1667 18.5133 7.20004 18.6667 7.2667Z"
              fill="#00B871"
            />
            <path
              d="M12.666 17.4466C12.446 17.48 12.226 17.5 11.9994 17.5C10.2127 17.5 8.67268 16.46 7.95268 14.9533C7.37268 15.4466 6.99935 16.18 6.99935 17V19.5H5.66602V17C5.66602 15.7933 6.46602 14.78 7.55935 14.4466H9.07268C9.60602 16.02 10.9794 17.2 12.666 17.4466Z"
              fill="#D5DBE1"
            />
            <path
              d="M12 18C9.24267 18 7 15.7573 7 13C7 10.2427 9.24267 7.99999 12 7.99999C14.7573 7.99999 17 10.2427 17 13C17 15.7573 14.7573 18 12 18ZM12 8.99999C9.794 8.99999 8 10.794 8 13C8 15.206 9.794 17 12 17C14.206 17 16 15.206 16 13C16 10.794 14.206 8.99999 12 8.99999Z"
              fill="#353643"
            />
            <path
              d="M18.8333 19.5001H17.8333V17.0001C17.8333 16.04 17.2146 15.206 16.2939 14.9254L16.5859 13.968C17.9306 14.378 18.8333 15.596 18.8333 17.0001V19.5001Z"
              fill="#353643"
            />
            <path
              d="M6.16699 19.5001H5.16699V17.0001C5.16699 15.5967 6.06966 14.378 7.41433 13.968L7.70633 14.9254C6.78566 15.206 6.16699 16.04 6.16699 17.0001V19.5001Z"
              fill="#353643"
            />
            <path d="M12.5 6.3333H13.5V8.3333H12.5V6.3333Z" fill="#353643" />
            <path d="M10.5 6.3333H11.5V8.3333H10.5V6.3333Z" fill="#353643" />
            <path d="M4 19.0001H20V20.0001H4V19.0001Z" fill="#353643" />
            <path
              d="M14.9053 6.66668H9.094C8.49067 6.66668 8 6.17601 8 5.57201V5.09468C8 4.49067 8.49067 4.00001 9.09467 4.00001H14.906C15.5093 4.00001 16 4.49067 16 5.09468V5.57268C16 6.17601 15.5093 6.66668 14.9053 6.66668ZM9.09467 5.00001C9.04267 5.00001 9 5.04201 9 5.09468V5.57268C9 5.62468 9.042 5.66734 9.09467 5.66734H14.906C14.958 5.66734 15.0007 5.62534 15.0007 5.57268V5.09468C15.0007 5.04268 14.9587 5.00001 14.906 5.00001H9.09467Z"
              fill="#353643"
            />
            <path
              d="M6.5 7.16663H5.5V6.66662C5.5 5.65529 6.322 4.83329 7.33333 4.83329H8.33333V5.83329H7.33333C6.874 5.83329 6.5 6.20729 6.5 6.66662V7.16663Z"
              fill="#353643"
            />
            <path
              d="M7.5 9.33326H4.5C4.224 9.33326 4 9.10926 4 8.83326V8.49993C4 7.4886 4.822 6.6666 5.83333 6.6666H6.16667C7.178 6.6666 8 7.4886 8 8.49993V8.83326C8 9.10926 7.776 9.33326 7.5 9.33326ZM5.01667 8.33326H6.98267C6.90533 7.95326 6.56867 7.6666 6.166 7.6666H5.83267C5.43067 7.6666 5.09467 7.95326 5.01667 8.33326Z"
              fill="#353643"
            />
            <path
              d="M18.5003 7.16663H17.5003V6.66662C17.5003 6.20729 17.1263 5.83329 16.667 5.83329H15.667V4.83329H16.667C17.6783 4.83329 18.5003 5.65529 18.5003 6.66662V7.16663Z"
              fill="#353643"
            />
            <path
              d="M19.5 9.33326H16.5C16.224 9.33326 16 9.10926 16 8.83326V8.49993C16 7.4886 16.822 6.6666 17.8333 6.6666H18.1667C19.178 6.6666 20 7.4886 20 8.49993V8.83326C20 9.10926 19.776 9.33326 19.5 9.33326ZM17.0167 8.33326H18.9827C18.9053 7.95326 18.5687 7.6666 18.166 7.6666H17.8327C17.4307 7.6666 17.0947 7.95326 17.0167 8.33326Z"
              fill="#353643"
            />
            <path
              d="M12 10.6666C12.2761 10.6666 12.5 10.4427 12.5 10.1666C12.5 9.89045 12.2761 9.6666 12 9.6666C11.7239 9.6666 11.5 9.89045 11.5 10.1666C11.5 10.4427 11.7239 10.6666 12 10.6666Z"
              fill="#353643"
            />
            <path
              d="M12 16.3333C12.2761 16.3333 12.5 16.1094 12.5 15.8333C12.5 15.5572 12.2761 15.3333 12 15.3333C11.7239 15.3333 11.5 15.5572 11.5 15.8333C11.5 16.1094 11.7239 16.3333 12 16.3333Z"
              fill="#353643"
            />
            <path
              d="M14.833 13.4999C15.1091 13.4999 15.333 13.2761 15.333 12.9999C15.333 12.7238 15.1091 12.4999 14.833 12.4999C14.5569 12.4999 14.333 12.7238 14.333 12.9999C14.333 13.2761 14.5569 13.4999 14.833 13.4999Z"
              fill="#353643"
            />
            <path
              d="M9.16602 13.4999C9.44216 13.4999 9.66602 13.2761 9.66602 12.9999C9.66602 12.7238 9.44216 12.4999 9.16602 12.4999C8.88987 12.4999 8.66602 12.7238 8.66602 12.9999C8.66602 13.2761 8.88987 13.4999 9.16602 13.4999Z"
              fill="#353643"
            />
            <path
              d="M14.3546 11.3505C14.5499 11.1553 14.5499 10.8387 14.3546 10.6434C14.1593 10.4482 13.8428 10.4482 13.6475 10.6434C13.4522 10.8387 13.4522 11.1553 13.6475 11.3505C13.8428 11.5458 14.1593 11.5458 14.3546 11.3505Z"
              fill="#353643"
            />
            <path
              d="M10.3478 15.3566C10.543 15.1613 10.543 14.8447 10.3478 14.6495C10.1525 14.4542 9.83592 14.4542 9.64066 14.6495C9.4454 14.8447 9.4454 15.1613 9.64066 15.3566C9.83592 15.5519 10.1525 15.5519 10.3478 15.3566Z"
              fill="#353643"
            />
            <path
              d="M14.3546 15.3573C14.5499 15.162 14.5499 14.8454 14.3546 14.6502C14.1593 14.4549 13.8428 14.4549 13.6475 14.6502C13.4522 14.8454 13.4522 15.162 13.6475 15.3573C13.8428 15.5525 14.1593 15.5525 14.3546 15.3573Z"
              fill="#353643"
            />
            <path
              d="M10.3478 11.3499C10.543 11.1546 10.543 10.8381 10.3478 10.6428C10.1525 10.4475 9.83592 10.4475 9.64066 10.6428C9.4454 10.8381 9.4454 11.1546 9.64066 11.3499C9.83593 11.5452 10.1525 11.5452 10.3478 11.3499Z"
              fill="#353643"
            />
          </svg>
          {/* <FastField
            component="input"
            type="text"
            name="phone"
            className={cx(styles.input, saleFormStyles.input)}
          /> */}
          <MaskedPhoneInput
            innerRef={phoneRef}
            isWhite={true}
            isLg={true}
            name="phone"
            value={values.phone}
            error={errors.phone}
            onChange={value => {
              setFieldValue("phone", value)
            }}
            placeholder={t("forms.onlineOrderFormNumberLabel")}
          />
        </div>
        {touched.phone && errors.phone && (
          <span className={cx(styles.error, saleFormStyles.error)}>
            {errors.phone}
          </span>
        )}
      </div>
      <Button2
        color="secondary"
        buttonType="submit"
        disabled={isSubmitting}
        className={saleFormStyles.contactFormButton}
      >
        {t("forms.btnText1") || t("forms.contactForm2.CTA")}
      </Button2>
      <Field
        component="input"
        type="hidden"
        name="isAffiliate"
        value={isAffiliate}
      />
      <Field
        component="input"
        type="hidden"
        name="affiliateId"
        value={affiliateId}
      />
    </Form>
  )
}

export const GiftCertificateForm = withFormik({
  mapPropsToValues: props => ({
    phone: "+420",
    referrer: "",
    ga: "",
    success: false,
    name: props.name,
    discount: props.discount,
    isAffiliate: false,
    affiliateId: null,
  }),
  validationSchema: () =>
    Yup.object().shape({
      phone: Yup.string()
        .min(12, <PhoneErrorMessage />)
        .phone(),
    }),
  handleSubmit: async (
    { phone, name, isAffiliate, affiliateId },
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    try {
      const UTMS = getUTM()
      let referrer = getReferrer()

      const isEn = document.location.pathname.includes("/en")
      const testovani = localStorage.getItem("website-version")

      const data = {
        form_name: "darkovy-certifikat",
        language: isEn ? "en" : "cs",
        region: process.env.GATSBY_COUNTRY,
        branchId: process.env.GATSBY_BRANCH_ID,
        phone: formatPhoneNumber(phone),
        utm_source: UTMS.UTM_SOURCE,
        utm_medium: UTMS.UTM_MEDIUM,
        utm_campaign: UTMS.UTM_CAMPAIGN,
        utm_term: UTMS.UTM_TERM,
        utm_content: UTMS.UTM_CONTENT,
        referrer: referrer,
        cjevent: affiliateId,
        roistat: getCookie("roistat_visit"),
        ga: getCookie("_ga"),
        testovani: testovani,
      }
      localStorage.setItem('FormName', data.form_name);

      const req = await fetch(`${process.env.GATSBY_APP_API_URL}/application`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })

      if (req.ok) {
        await setSubmitting(false)
        await setFieldValue("success", true)

        // await console.log("data", JSON.stringify(data))

        const response = await req.json()

        if (isAffiliate) {
          const getParamsData = Object.entries(response)
            .map(([key, value]) => `${key}=${value || 0}`)
            .reduce(
              (previousValue, currentValue) =>
                `${previousValue}&${currentValue}`
            )
          window.location.href = isEn
            ? `/en/thank-you-affiliate/?${getParamsData}&actionTrackerId=430565`
            : `/thank-you-affiliate/?${getParamsData}&actionTrackerId=430565`
        } else {
          window.location.href = isEn ? "/en/thank-you" : "/thank-you"
        }

        setTimeout(() => {
          resetForm()
          window.dataLayer.push({
            event: "ga.pageview",
            pageURL: isEn ? "/en/thank-you-dietolog" : "/thank-you-dietolog",
            pageType: "Purchase",
            testovani: testovani,
          })
          window.dataLayer.push({
            event: "ga.event",
            eCategory: "sleva",
            eAction: "",
            eLabel: "Homepage",
          })
        }, 300)
      }
    } catch (err) {
      setSubmitting(false)
      setFieldValue("success", false)
      alert("Something went wrong, please try again!")
    }
  },
})(GiftCertificateFormLayout)
