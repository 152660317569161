import {
  OrderProgramDurationType,
  OrderCustomerGenderType,
  OrderProgramType,
  OrderNumberOfMeals,
} from './order-food-online-type'


export const OrderFoodOnlineData = [
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Three,
    OrderProgramDurationType.TwoWeeks,
    1100,
    461,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Three,
    OrderProgramDurationType.OneMonth,
    1100,
    439,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Three,
    OrderProgramDurationType.TwoMonth,
    1100,
    419,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Three,
    OrderProgramDurationType.ThreeMonth,
    1100,
    398,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Three,
    OrderProgramDurationType.TwoWeeks,
    1300,
    461,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Three,
    OrderProgramDurationType.OneMonth,
    1300,
    439,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Three,
    OrderProgramDurationType.TwoMonth,
    1300,
    419,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Three,
    OrderProgramDurationType.ThreeMonth,
    1300,
    398,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Four,
    OrderProgramDurationType.TwoWeeks,
    1600,
    547,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Four,
    OrderProgramDurationType.OneMonth,
    1600,
    528,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Four,
    OrderProgramDurationType.TwoMonth,
    1600,
    509,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Four,
    OrderProgramDurationType.ThreeMonth,
    1600,
    482,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Four,
    OrderProgramDurationType.TwoWeeks,
    1800,
    566,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Four,
    OrderProgramDurationType.OneMonth,
    1800,
    547,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Four,
    OrderProgramDurationType.TwoMonth,
    1800,
    528,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Four,
    OrderProgramDurationType.ThreeMonth,
    1800,
    500,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.TwoWeeks,
    1600,
    608,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.OneMonth,
    1600,
    587,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.TwoMonth,
    1600,
    566,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.ThreeMonth,
    1600,
    535,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.TwoWeeks,
    1800,
    629,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.OneMonth,
    1800,
    608,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.TwoMonth,
    1800,
    587,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.ThreeMonth,
    1800,
    555,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Three,
    OrderProgramDurationType.TwoWeeks,
    1400,
    461,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Three,
    OrderProgramDurationType.OneMonth,
    1400,
    439,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Three,
    OrderProgramDurationType.TwoMonth,
    1400,
    419,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Three,
    OrderProgramDurationType.ThreeMonth,
    1400,
    398,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Three,
    OrderProgramDurationType.TwoWeeks,
    1600,
    482,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Three,
    OrderProgramDurationType.OneMonth,
    1600,
    461,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Three,
    OrderProgramDurationType.TwoMonth,
    1600,
    440,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Three,
    OrderProgramDurationType.ThreeMonth,
    1600,
    419,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Four,
    OrderProgramDurationType.TwoWeeks,
    1900,
    584,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Four,
    OrderProgramDurationType.OneMonth,
    1900,
    566,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Four,
    OrderProgramDurationType.TwoMonth,
    1900,
    547,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Four,
    OrderProgramDurationType.ThreeMonth,
    1900,
    519,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Four,
    OrderProgramDurationType.TwoWeeks,
    2100,
    584,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Four,
    OrderProgramDurationType.OneMonth,
    2100,
    566,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Four,
    OrderProgramDurationType.TwoMonth,
    2100,
    547,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Four,
    OrderProgramDurationType.ThreeMonth,
    2100,
    519,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.TwoWeeks,
    1900,
    649,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.OneMonth,
    1900,
    629,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.TwoMonth,
    1900,
    608,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.ThreeMonth,
    1900,
    577,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.TwoWeeks,
    2100,
    649,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.OneMonth,
    2100,
    629,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.TwoMonth,
    2100,
    608,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.ThreeMonth,
    2100,
    577,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.RecruitmentProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.TwoWeeks,
    2200,
    649,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.RecruitmentProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.OneMonth,
    2200,
    629,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.RecruitmentProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.TwoMonth,
    2200,
    608,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.RecruitmentProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.ThreeMonth,
    2200,
    577,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.RecruitmentProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.TwoWeeks,
    2600,
    670,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.RecruitmentProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.OneMonth,
    2600,
    650,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.RecruitmentProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.TwoMonth,
    2600,
    629,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.RecruitmentProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.ThreeMonth,
    2600,
    598,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.LunchAndDinnerProgram,
    OrderNumberOfMeals.Two,
    OrderProgramDurationType.TwoWeeks,
    1000,
    377,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.LunchAndDinnerProgram,
    OrderNumberOfMeals.Two,
    OrderProgramDurationType.OneMonth,
    1000,
    356,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.LunchAndDinnerProgram,
    OrderNumberOfMeals.Two,
    OrderProgramDurationType.TwoMonth,
    1000,
    339,
  ],
  [
    OrderCustomerGenderType.Female,
    OrderProgramType.LunchAndDinnerProgram,
    OrderNumberOfMeals.Two,
    OrderProgramDurationType.ThreeMonth,
    1000,
    324,
  ],

  [
    OrderCustomerGenderType.Male,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Three,
    OrderProgramDurationType.TwoWeeks,
    1600,
    482,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Three,
    OrderProgramDurationType.OneMonth,
    1600,
    461,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Three,
    OrderProgramDurationType.TwoMonth,
    1600,
    440,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Three,
    OrderProgramDurationType.ThreeMonth,
    1600,
    419,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Three,
    OrderProgramDurationType.TwoWeeks,
    1800,
    503,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Three,
    OrderProgramDurationType.OneMonth,
    1800,
    482,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Three,
    OrderProgramDurationType.TwoMonth,
    1800,
    461,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Three,
    OrderProgramDurationType.ThreeMonth,
    1800,
    439,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Four,
    OrderProgramDurationType.TwoWeeks,
    1800,
    566,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Four,
    OrderProgramDurationType.OneMonth,
    1800,
    547,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Four,
    OrderProgramDurationType.TwoMonth,
    1800,
    528,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Four,
    OrderProgramDurationType.ThreeMonth,
    1800,
    500,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Four,
    OrderProgramDurationType.TwoWeeks,
    2000,
    584,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Four,
    OrderProgramDurationType.OneMonth,
    2000,
    566,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Four,
    OrderProgramDurationType.TwoMonth,
    2000,
    547,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Four,
    OrderProgramDurationType.ThreeMonth,
    2000,
    519,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.TwoWeeks,
    1800,
    629,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.OneMonth,
    1800,
    608,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.TwoMonth,
    1800,
    587,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.ThreeMonth,
    1800,
    555,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.TwoWeeks,
    2000,
    649,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.OneMonth,
    2000,
    629,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.TwoMonth,
    2000,
    608,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.WeightLossProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.ThreeMonth,
    2000,
    577,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Three,
    OrderProgramDurationType.TwoWeeks,
    1900,
    524,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Three,
    OrderProgramDurationType.OneMonth,
    1900,
    503,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Three,
    OrderProgramDurationType.TwoMonth,
    1900,
    482,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Three,
    OrderProgramDurationType.ThreeMonth,
    1900,
    461,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Three,
    OrderProgramDurationType.TwoWeeks,
    1900,
    524,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Three,
    OrderProgramDurationType.OneMonth,
    1900,
    503,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Three,
    OrderProgramDurationType.TwoMonth,
    1900,
    482,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Three,
    OrderProgramDurationType.ThreeMonth,
    1900,
    461,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Four,
    OrderProgramDurationType.TwoWeeks,
    2100,
    584,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Four,
    OrderProgramDurationType.OneMonth,
    2100,
    566,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Four,
    OrderProgramDurationType.TwoMonth,
    2100,
    547,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Four,
    OrderProgramDurationType.ThreeMonth,
    2100,
    519,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Four,
    OrderProgramDurationType.TwoWeeks,
    2300,
    584,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Four,
    OrderProgramDurationType.OneMonth,
    2300,
    566,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Four,
    OrderProgramDurationType.TwoMonth,
    2300,
    547,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Four,
    OrderProgramDurationType.ThreeMonth,
    2300,
    519,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.TwoWeeks,
    2100,
    649,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.OneMonth,
    2100,
    629,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.TwoMonth,
    2100,
    608,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.ThreeMonth,
    2100,
    577,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.TwoWeeks,
    2300,
    649,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.OneMonth,
    2300,
    629,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.TwoMonth,
    2300,
    608,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.MaintenanceProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.ThreeMonth,
    2300,
    577,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.RecruitmentProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.TwoWeeks,
    2400,
    649,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.RecruitmentProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.OneMonth,
    2400,
    629,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.RecruitmentProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.TwoMonth,
    2400,
    608,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.RecruitmentProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.ThreeMonth,
    2400,
    577,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.RecruitmentProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.TwoWeeks,
    2600,
    670,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.RecruitmentProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.OneMonth,
    2600,
    650,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.RecruitmentProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.TwoMonth,
    2600,
    629,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.RecruitmentProgram,
    OrderNumberOfMeals.Five,
    OrderProgramDurationType.ThreeMonth,
    2600,
    598,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.LunchAndDinnerProgram,
    OrderNumberOfMeals.Two,
    OrderProgramDurationType.TwoWeeks,
    1000,
    377,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.LunchAndDinnerProgram,
    OrderNumberOfMeals.Two,
    OrderProgramDurationType.OneMonth,
    1000,
    356,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.LunchAndDinnerProgram,
    OrderNumberOfMeals.Two,
    OrderProgramDurationType.TwoMonth,
    1000,
    339,
  ],
  [
    OrderCustomerGenderType.Male,
    OrderProgramType.LunchAndDinnerProgram,
    OrderNumberOfMeals.Two,
    OrderProgramDurationType.ThreeMonth,
    1000,
    324,
  ],
]

export const findOrderFood = data => {
  if (!data) return undefined
  return OrderFoodOnlineData.find(
    ([gender, program, meals, duration, kCal]) => {
      return arrayEquals([gender, program, meals, duration, kCal], data)
    }
  )
}

export const findOrderFoodPrice = data => {
  const [, , , , , price] = findOrderFood(data) || []
  return price || 0
}

export const findOrderFoodKcalVariants = data => {
  if (!data) return [0]

  const kCalVariants = OrderFoodOnlineData.filter(
    ([gender, program, meals]) => {
      return arrayEquals([gender, program, meals], data)
    }
  ).map(([, , , , kCal]) => kCal)

  const result = kCalVariants.filter((item, pos) => {
    return kCalVariants.indexOf(item) === pos
  })

  return result
}

export const findOrderFoodDurationPriceVariants = data => {
  if (!data) return []

  const result = OrderFoodOnlineData.filter(
    ([gender, program, meals, length, kCal]) => {
      return arrayEquals([gender, program, meals, kCal], data)
    }
  ).map(([, , , , , price]) => price)

  return result
}

const arrayEquals = (a, b) => {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  )
}
