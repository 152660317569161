export const OrderFoodOnlineType = {
  NumberOfPeople: 'NumberOfPeople',
  CustomerGender: 'CustomerGender',
  CustomerMenu: 'CustomerMenu',
  Program: 'Program',
  NumberOfMeals: 'NumberOfMeals',
  CountOfMeals: 'CountOfMeals',
  NumberOfKcal: 'NumberOfKcal',
  ProgramDuration: 'ProgramDuration',
  WeekMealLength: 'WeekMealLength',
  PriceDiscount: 'PriceDiscount',
  Discount: 'Discount',
  PromoCode: 'PromoCode',
  IsCompanyOrder: 'IsCompanyOrder',
  Exeptions: 'Exeptions',
  OrderProgram: 'OrderProgram',
  Duration:'Duration'
}

export const OrderNumberOfPeopleType = {
  One: 1,
  Two: 2,
  ThreeOrMore: 3,
  TheCompany: 5,
}

export const OrderCustomerGenderType = {
  Male: 'MALE',
  Female: 'FEMALE',
}

export const OrderCustomerMenuType = {
  Meat: 'MEAT',
  Vege: 'VEGE',
  BEZ_RIB: 'BEZ_RIB',
  Fish: 'FISH',
}

export const OrderCustomerMenuForWho = {
  ForMe: 'FOR_ME',
  People2: '2_PEOPLE',
  People3: '3_PEOPLE',
  People5: '5_PEOPLE',
}

export const OrderCustomerProgramType = {
  Basic: 'BASIC',
  Slimming: 'SLIMMING',
  Maintenance: 'MAINTENANCE',
  Pickling: 'PICKLING'

}

export const OrderCustomerWeekType = {
  Monday: 'MONDAY',
  Tuesday: 'TUESDAY',
  Wednesday: 'WEDNESDAY',
  Thursday: 'THURSDAY',
  Friday: 'FRIDAY',
  Saturday: 'SATURDAY'
}


export const OrderProgramType = {
  WeightLossProgram: 'WEIGHT_LOSS_PROGRAM',
  RecruitmentProgram: 'RECRUITMENT_PROGRAM',
  MaintenanceProgram: 'MAINTENANCE_PROGRAM',
  LunchAndDinnerProgram: 'LUNCH_AND_DINNER',
  Kkal1000: '1000_KKal',
  Kkal1600: '1600_KKal',
}

export const OrderProgramDurationType = {
  TwoWeeks: 'TWO_WEEKS',
  OneMonth: 'ONE_MONTH',
  TwoMonth: 'TWO_MONTH',
  ThreeMonth: 'THREE_MONTH',
}

export const OrderNumberOfMealsType = {
  Breakfast: 'BREAKFAST',
  SnackFirst: 'SNACK_FIRST',
  Lunch: 'LUNCH',
  SnackSecond: 'SNACK_SECOND',
  Dinner: 'DINNER',
}

export const OrderNumberOfMeals = {
  One: 1,
  Two: 2,
  Three: 3,
  Four: 4,
  Five: 5,
}

export const OrderCountOfMeals = {
  One: 1,
  Two: 2,
  Three: 3,
  Four: 4,
  Five: 5,
}

export const OrderWeekMealLengthType = {
  FiveDays: '5_DAYS',
  SixDays: '6_DAYS',
}
