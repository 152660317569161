import React from 'react'

export const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      id="icons8-macos-close_1_"
      data-name="icons8-macos-close (1)"
      d="M11,3a8,8,0,1,0,8,8A8,8,0,0,0,11,3Zm3.138,10.2a.667.667,0,1,1-.943.943l-2.2-2.2-2.2,2.2a.667.667,0,1,1-.943-.943l2.2-2.2-2.2-2.2A.667.667,0,0,1,8.8,7.862l2.2,2.2,2.2-2.2a.667.667,0,0,1,.943.943l-2.2,2.2Z"
      transform="translate(-3 -3)"
      fill="#353643"
    />
  </svg>
)

export const ArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="10.94"
    viewBox="0 0 16 10.94"
  >
    <path
      id="icons8-up"
      d="M5.47,0,4.824.661.271,5.2A.924.924,0,0,0,1.578,6.507L4.551,3.519V15.067a.919.919,0,1,0,1.839,0V3.519L9.363,6.507A.924.924,0,1,0,10.67,5.2L6.117.661Z"
      transform="translate(16) rotate(90)"
      fill="#353643"
    />
  </svg>
)
