import cx from 'classnames';
import { graphql, Link as GatsbyLink, useStaticQuery } from 'gatsby';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import { LangContext, useLangContext } from '../../../../utils/lang';
import styles from './lang-menu.module.css';

const LanguageMenu = props => {
  const { t, i18n } = useTranslation()
  const [activeLang, setaActiveLang] = useState("cz")

  const handleActiveLang = value => setaActiveLang(value)

  return (
    <Select
      options={[
        {
          value: "cz",
          label: "cz",
        },
        {
          value: "en",
          label: "en",
        },
      ]}
      isSearchable={false}
      value={{
        value: "cz",
        label: "cz",
      }}
      onChange={e => handleActiveLang(e.value)}
    />
  )
}

export default LanguageMenu

export const LanguagePicker = ({ isLight = false }) => {
  const { lang, originalPath } = useLangContext()
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            supportedLanguages
          }
        }
      }
    `
  )

  return (
    <div className={styles.langMenu}>
      {site.siteMetadata.supportedLanguages.map(supportedLang => (
        <a
          aria-label={`Change language to ${supportedLang}`}
          href={
            supportedLang === "cz"
              ? `${originalPath}`
              : `/${supportedLang}${originalPath}`
          }
          key={supportedLang}
          className={cx(styles.langMenuItem, {
            [styles.active]: lang === supportedLang,
            [styles.isLight]: isLight,
          })}
          // style={{
          //   pointerEvents: supportedLang === "en" ? "none" : "",
          //   opacity: supportedLang === "en" ? "0.5" : "",
          //   cursor: supportedLang === "en" ? "default" : "",
          // }}
        >
          {supportedLang === "cz" ? (
            <>
              {lang === supportedLang ? (
                <svg
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.4872 27C20.936 27 26.9744 20.9558 26.9744 13.5C26.9744 6.04416 20.936 0 13.4872 0C6.03843 0 0 6.04416 0 13.5C0 20.9558 6.03843 27 13.4872 27Z"
                    fill="#F0F0F0"
                  />
                  <path
                    d="M12.3146 13.5C12.3146 13.5 3.95834 23.0478 3.95044 23.0459C6.3911 25.4889 9.76296 27 13.4874 27C20.9361 27 26.9746 20.9558 26.9746 13.5H12.3146Z"
                    fill="#D80027"
                  />
                  <path
                    d="M3.95031 3.9541C-1.31677 9.22617 -1.31677 17.7739 3.95031 23.0461C6.12686 20.8674 8.22017 18.7721 13.4872 13.5001L3.95031 3.9541Z"
                    fill="#0052B4"
                  />
                </svg>
              ) : (
                <svg
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.5">
                    <path
                      d="M13.4872 27C20.936 27 26.9744 20.9558 26.9744 13.5C26.9744 6.04416 20.936 0 13.4872 0C6.03843 0 0 6.04416 0 13.5C0 20.9558 6.03843 27 13.4872 27Z"
                      fill="#F0F0F0"
                    />
                    <path
                      d="M12.3146 13.5C12.3146 13.5 3.95834 23.0478 3.95044 23.0459C6.3911 25.4889 9.76296 27 13.4874 27C20.9361 27 26.9746 20.9558 26.9746 13.5H12.3146Z"
                      fill="#D80027"
                    />
                    <path
                      d="M3.95031 3.9541C-1.31677 9.22617 -1.31677 17.7739 3.95031 23.0461C6.12686 20.8674 8.22017 18.7721 13.4872 13.5001L3.95031 3.9541Z"
                      fill="#0052B4"
                    />
                  </g>
                </svg>
              )}
            </>
          ) : (
            <>
              {lang === supportedLang ? (
                <svg
                  width="28"
                  height="27"
                  viewBox="0 0 28 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.4618 27C21.9106 27 27.9491 20.9558 27.9491 13.5C27.9491 6.04416 21.9106 0 14.4618 0C7.01304 0 0.974609 6.04416 0.974609 13.5C0.974609 20.9558 7.01304 27 14.4618 27Z"
                    fill="white"
                  />
                  <path
                    d="M3.76338 5.28027C2.70395 6.65996 1.90509 8.25017 1.43994 9.97769H8.45635L3.76338 5.28027Z"
                    fill="#0052B4"
                  />
                  <path
                    d="M27.4846 9.97862C27.0194 8.25115 26.2205 6.66094 25.1611 5.28125L20.4683 9.97862H27.4846Z"
                    fill="#0052B4"
                  />
                  <path
                    d="M1.43994 17.0215C1.90515 18.749 2.704 20.3392 3.76338 21.7188L8.45619 17.0215H1.43994Z"
                    fill="#0052B4"
                  />
                  <path
                    d="M22.6739 2.79054C21.2955 1.7301 19.7068 0.930488 17.981 0.464844V7.48785L22.6739 2.79054Z"
                    fill="#0052B4"
                  />
                  <path
                    d="M6.25073 24.209C7.62912 25.2694 9.21782 26.069 10.9437 26.5347V19.5117L6.25073 24.209Z"
                    fill="#0052B4"
                  />
                  <path
                    d="M10.9436 0.464844C9.21776 0.930488 7.62906 1.7301 6.25073 2.79048L10.9436 7.4878V0.464844Z"
                    fill="#0052B4"
                  />
                  <path
                    d="M17.981 26.5347C19.7068 26.069 21.2955 25.2694 22.6738 24.209L17.981 19.5117V26.5347Z"
                    fill="#0052B4"
                  />
                  <path
                    d="M20.4683 17.0215L25.1611 21.7189C26.2205 20.3392 27.0194 18.749 27.4846 17.0215H20.4683Z"
                    fill="#0052B4"
                  />
                  <path
                    d="M27.8349 11.7391H16.2211L16.2211 0.114275C15.6452 0.0392344 15.0581 0 14.4618 0C13.8655 0 13.2785 0.0392344 12.7026 0.114275V11.739L1.08878 11.7391C1.01381 12.3155 0.974609 12.9032 0.974609 13.5C0.974609 14.0969 1.01381 14.6845 1.08878 15.2609H12.7025L12.7026 26.8857C13.2785 26.9608 13.8655 27 14.4618 27C15.0581 27 15.6452 26.9608 16.221 26.8857V15.261L27.8349 15.2609C27.9099 14.6845 27.9491 14.0969 27.9491 13.5C27.9491 12.9032 27.9099 12.3155 27.8349 11.7391Z"
                    fill="#D80027"
                  />
                  <path
                    d="M17.98 17.0215L23.9984 23.0457C24.2752 22.7687 24.5393 22.4792 24.7912 22.179L19.6386 17.0215H17.98V17.0215Z"
                    fill="#0052B4"
                  />
                  <path
                    d="M10.9436 17.0215H10.9435L4.92505 23.0456C5.20175 23.3227 5.49104 23.587 5.79092 23.8391L10.9436 18.6815V17.0215Z"
                    fill="#0052B4"
                  />
                  <path
                    d="M10.9431 9.97843V9.97832L4.92463 3.9541C4.64783 4.23106 4.38377 4.52063 4.13184 4.82079L9.28453 9.97838H10.9431V9.97843Z"
                    fill="#0052B4"
                  />
                  <path
                    d="M17.98 9.97792L23.9985 3.95365C23.7218 3.67658 23.4325 3.41228 23.1326 3.16016L17.98 8.31774V9.97792V9.97792Z"
                    fill="#0052B4"
                  />
                </svg>
              ) : (
                <svg
                  width="28"
                  height="27"
                  viewBox="0 0 28 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.5">
                    <path
                      d="M14.4618 27C21.9106 27 27.9491 20.9558 27.9491 13.5C27.9491 6.04416 21.9106 0 14.4618 0C7.01304 0 0.974609 6.04416 0.974609 13.5C0.974609 20.9558 7.01304 27 14.4618 27Z"
                      fill="white"
                    />
                    <path
                      d="M3.76338 5.28027C2.70395 6.65996 1.90509 8.25017 1.43994 9.97769H8.45635L3.76338 5.28027Z"
                      fill="#0052B4"
                    />
                    <path
                      d="M27.4846 9.97862C27.0194 8.25115 26.2205 6.66094 25.1611 5.28125L20.4683 9.97862H27.4846Z"
                      fill="#0052B4"
                    />
                    <path
                      d="M1.43994 17.0215C1.90515 18.749 2.704 20.3392 3.76338 21.7188L8.45619 17.0215H1.43994Z"
                      fill="#0052B4"
                    />
                    <path
                      d="M22.6739 2.79054C21.2955 1.7301 19.7068 0.930488 17.981 0.464844V7.48785L22.6739 2.79054Z"
                      fill="#0052B4"
                    />
                    <path
                      d="M6.25073 24.209C7.62912 25.2694 9.21782 26.069 10.9437 26.5347V19.5117L6.25073 24.209Z"
                      fill="#0052B4"
                    />
                    <path
                      d="M10.9436 0.464844C9.21776 0.930488 7.62906 1.7301 6.25073 2.79048L10.9436 7.4878V0.464844Z"
                      fill="#0052B4"
                    />
                    <path
                      d="M17.981 26.5347C19.7068 26.069 21.2955 25.2694 22.6738 24.209L17.981 19.5117V26.5347Z"
                      fill="#0052B4"
                    />
                    <path
                      d="M20.4683 17.0215L25.1611 21.7189C26.2205 20.3392 27.0194 18.749 27.4846 17.0215H20.4683Z"
                      fill="#0052B4"
                    />
                    <path
                      d="M27.8349 11.7391H16.2211L16.2211 0.114275C15.6452 0.0392344 15.0581 0 14.4618 0C13.8655 0 13.2785 0.0392344 12.7026 0.114275V11.739L1.08878 11.7391C1.01381 12.3155 0.974609 12.9032 0.974609 13.5C0.974609 14.0969 1.01381 14.6845 1.08878 15.2609H12.7025L12.7026 26.8857C13.2785 26.9608 13.8655 27 14.4618 27C15.0581 27 15.6452 26.9608 16.221 26.8857V15.261L27.8349 15.2609C27.9099 14.6845 27.9491 14.0969 27.9491 13.5C27.9491 12.9032 27.9099 12.3155 27.8349 11.7391Z"
                      fill="#D80027"
                    />
                    <path
                      d="M17.98 17.0215L23.9984 23.0457C24.2752 22.7687 24.5393 22.4792 24.7912 22.179L19.6386 17.0215H17.98V17.0215Z"
                      fill="#0052B4"
                    />
                    <path
                      d="M10.9436 17.0215H10.9435L4.92505 23.0456C5.20175 23.3227 5.49104 23.587 5.79092 23.8391L10.9436 18.6815V17.0215Z"
                      fill="#0052B4"
                    />
                    <path
                      d="M10.9431 9.97843V9.97832L4.92463 3.9541C4.64783 4.23106 4.38377 4.52063 4.13184 4.82079L9.28453 9.97838H10.9431V9.97843Z"
                      fill="#0052B4"
                    />
                    <path
                      d="M17.98 9.97792L23.9985 3.95365C23.7218 3.67658 23.4325 3.41228 23.1326 3.16016L17.98 8.31774V9.97792V9.97792Z"
                      fill="#0052B4"
                    />
                  </g>
                </svg>
              )}
            </>
          )}
        </a>
      ))}
    </div>
  )
}
