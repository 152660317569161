import { useState, useEffect } from 'react'

export const isLocaleStorageEmpty = (storage) => {
  if (isArray(storage)) {
    return isEmptyArray(storage)
  }

  if (isObject(storage)) {
    return isEmptyObject(storage)
  }

  return !storage
}

const isEmptyObject = (obj) => {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false
    }
  }

  return JSON.stringify(obj) === JSON.stringify({})
}

const isEmptyArray = (arr) => {
  if (Array.isArray(arr)) {
    return arr.length >= 1
  }
  return false
}

const isArray = (storage) => {
  return Array.isArray(storage)
}

const isObject = (storage) => {
  return (
    typeof storage === 'object' && storage !== null && !Array.isArray(storage)
  )
}

const getStorageValue = (key, defaultValue) => {
  // getting stored value
  const saved = localStorage.getItem(key)
  const initial = JSON.parse(saved)
  return initial || defaultValue
}

export const useLocalStorage = (key, defaultValue) => {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue)
  })

  useEffect(() => {
    // storing input name
    localStorage.setItem(key, JSON.stringify(value))
  }, [key, value])

  return [value, setValue]
}
